import { ComponentClass, FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
import * as store from 'stores';

import type { IGroupState } from '@types';
import { RootState, useAppSelector } from 'redux/store';

type ComponentType<P = {}> = ComponentClass<P> | FunctionComponent<P>;
type Props = {
  component?: ComponentType | React.ReactNode | React.ReactElement | JSX.Element | React.ReactPortal | any;
  location?: string;
  groupState?: IGroupState;
  setGroupState?: (groupState: IGroupState) => void;
};

/**
 * @description ProtectedRoute
 * @Class
 * @category App Router
 * @subcategory Router
 */
const ProtectedRoute = ({ component: Component, ...props }: Props) => {
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  return <>{!isLogin ? <Navigate to="/" state={{ from: props.location }} replace /> : <Component {...props} />}</>;
};

export default ProtectedRoute;
